import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Link from '../../components/link/link';
import SEO from '../../components/seo/seo';

import './end.css';

const End = () => (
  <>
    <SEO title="The End" />
    <main className="end">
      <h1>The End</h1>
      <StaticImage
        alt="A row of socks"
        className="end__socks"
        height={50}
        layout="constrained"
        loading="eager"
        placeholder="tracedSVG"
        src="../../images/socks.png"
        width={332}
      />
      <div className="end__navigation">
        <Link
          direction="left"
          swipe
          to="/"
        >
          Home
        </Link>
        <Link
          direction="left"
          swipe
          to="/author"
        >
          About the author
        </Link>
        <Link
          transition={false}
          to="/shop"
        >
          Buy the book
        </Link>
      </div>
    </main>
  </>
);

export default End;
